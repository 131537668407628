import React from 'react'
import Layout from '../components/layout/Layout'
import Header from '../components/layout/Header'
import FeaturedSection from '../components/layout/FeaturedSection'
import About1 from '../components/images/About_1'
import About2 from '../components/images/About_2'
import About3 from '../components/images/About_3'
import FloatingContainer from '../components/floats/FloatingContainer'
import FloatingShape from '../components/floats/FloatingShape'
// Images
import ShapeSqft from '../images/data/sqft.svg'
import ShapeDesks from '../images/data/desks.svg'
import ShapeClients from '../images/data/clients.svg'
import ShapeCities from '../images/data/cities.svg'
import ParallaxContainer from '../components/parallax/ParallaxContainer'
import BotButton from '../components/landBot/BotButton'
import { Link } from 'gatsby'

const About = (props) => {
    return (
        <Layout
            gutters={false}
            container={false}
            activePage="/about"
            location={props.location}
        >
            <Header
                title="About Us | From Scale Ups To Gobal Giants | Kontor"
                description="Learn about the Kontor story. From launching in 2014, to help scale ups find their dream office, to working with some of the biggest companies across the globe."
            />
            {/*  Part 1 - Something had to change */}
            <div className="px-0 my-12 md:my-40 lg:mt-56 lg:mb-56">
                <h1 data-aos="fade-up" data-aos-duration="1000"
                    className="text-center mt-32 lg:mb-48 px-8 text-4xl">Why we do what we do</h1>

                <FloatingContainer
                    className="w-1/2 lef-1/4 lg:-top-32 "
                    floatSpeed="custom"
                    speed={5000}
                    floatType="wobbleDrift"
                    height="400px"
                >
                    <FloatingShape
                        height={180}
                        width={180}
                        shape={"triangle"}
                    />
                </FloatingContainer>
                <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800" className="flex flex-col md:flex-row mt-24 ">
                    <div className=" w-full md:w-1/2 leading-relaxed md:px-6 lg:px-12 max-w-xl ml-auto md:mr-6 lg:mr-24 px-8  md:px-0">
                        <p className="font-bold uppercase">
                            Rewind to November 2014.
                        </p>
                        <p>
                            Big biz is still stuck in the mud after the financial crisis. A tide of tech and creative startups is on the rise… and let’s imagine you’re one of them.
                        </p>
                        <p>
                            You’re on a mission with tons of potential, doing your best work together, making a dent in the universe. Yet you’re struggling to find an office.
                        </p>
                        <p>
                            The big office brokers are focused on their fellow big businesses. Biiiiig deals, with leases that go on and on and on.
                        </p>
                        <p>
                            Fair enough. But what are you and the rest of the gang supposed to do?
                        </p>
                        <p>
                            Something had to change. That’s why our two founders left their corporate office gigs and set up Kontor.
                        </p>
                    </div>
                    <div className="w-full md:w-1/2 flex flex-col justify-center">
                        <About1
                            className="ml-auto   w-full mt-12 md:mt-0"
                        />
                    </div>
                </div>
            </div>

            {/*  Part 2 - Something had to change */}

            <div className="px-0 my:24 mt-32 lg:my-48  ">
                <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-offset="-50"
                    className="text-center lg:px-0 px-8 sm:px-24 mb-32">
                    Kontor came along to help <span className="border-b-2 border-secondary font-bold">scaleups and unicorns</span> find the offices they need</h2>
                <div data-aos="fade-up" data-aos-duration="1000" className="flex flex-col md:flex-row mt-24 lg:mt-48">

                    <div className="w-full md:w-1/2 flex flex-col justify-center order-second md:order-first md:mt-0 mt-32">
                        <About2
                            className="mr-auto w-full"
                            alt="Example Office 2"
                        />
                    </div>
                    <div className="w-full md:w-1/2 leading-loose md:px-6 lg:px-12 max-w-xl mx-right md:ml-6 lg:ml-24 flex flex-col px-8  md:px-0 order-first md:order-second">
                        <FloatingContainer
                            className="w-1/2 lef-1/4 "
                            floatSpeed="custom"
                            speed={5000}
                            floatType="spin"
                            height="200"
                        >
                            <FloatingShape
                                height={180}
                                width={180}
                                shape={"halfMoon"}
                            />
                        </FloatingContainer>
                        <p>
                            To get them access to inspiring spaces where their teams actually want to work. To give honest and expert advice every step of the way. To make sure they weren’t taken to the cleaners.
                        </p>
                        <p>
                            And we were in it for the long haul.
                        </p>
                        <p>
                            Helping startups scale from 10 desks to 20, from 50 to 100, from 100 to infinity. Helping them expand internationally. And helping them get through the tough times too. The downsizing, the budget cuts, and yes... the craziness of coronavirus.
                        </p>
                    </div>
                </div>
            </div>


            {/* Part 3 - Serving well and doing things differently */}

            <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800" className="px-0 flex flex-col md:flex-row mt-24 ">
                <div className=" w-full md:w-1/2 leading-relaxed md:px-6 lg:px-12 max-w-xl ml-auto md:mr-6 lg:mr-24 px-8  md:px-0">
                    <p>
                        To serve them well, we had to break the mould.
                    </p>
                    <p>
                        Square footage isn’t the only thing that matters in an office search. We had to get under the skin of what makes clients tick, their vibe, where they are on their journey.
                    </p>
                    <p>
                    And with the world’s pace of change always on the up, “business as usual” was no longer part of the playbook. Not in 2014, and certainly not today...
                        </p>
                    <FloatingContainer
                        className="w-1/2 lef-1/4 "
                        floatSpeed="custom"
                        speed={5000}
                        floatType="wobbleDrift"
                        height="200px"
                    >
                        <FloatingShape
                            height={180}
                            width={180}
                            shape={"polygon"}
                        />
                    </FloatingContainer>
                    <p>
                        Founders, managers and ops bods had enough on their plate already. So we had to step it up to deliver the support they needed.
                    </p>
                    <p>
                        This meant replying to emails in minutes, not weeks. Building tech to take away hassle. Hiring a team who bust open the stereotypes about brokers, but also know their stuff inside out and can tease out requirements the client never even knew they had.
                    </p>
                    <p>
                        Because wouldn’t it be nice if you could actually look forward to the office hunt?
                    </p>
                </div>
                <div className="w-full md:w-1/2 flex flex-col">
                    {/* <img className="ml-auto   w-full mt-12 md:mt-0"
                        src={Office3} alt="Office Example - 3"
                    /> */}
                    <About3
                            className="ml-auto w-full mt-12 md:mt-0"
                            style={{  
                                webkitTransform: 'scaleX(-1)',
                                transform: 'scaleX(-1)'
                            }}
                        />
                </div>
            </div>



            {/* Part 4: Now it is 2019... */}

            <div data-aos="fade-up" data-aos-duration="1000" className="px-0 mt-32 ">
                <div className="flex flex-col md:flex-row mx-auto">
                    <div className="flex flex-col w-full md:w-1/2 justify-center mt-32 md:mt-0 max-w-2xl ml-auto  order-second md:order-first">
                        <div className="relative">
                            <ParallaxContainer
                                style={{ minHeight: 500 }}
                                className="flex-col"
                            >
                                <img className="self-center relative z-1 "
                                    src={ShapeSqft}
                                    width="275px" 
                                    height="275px"
                                    alt="3 Million Square Feet Acquired" />
                                <img className="self-center absolute bottom-0 right-16 z-0 "
                                    src={ShapeDesks}
                                    width="277px" 
                                    height="264px"
                                    alt="45000 Desks Set" />

                            </ParallaxContainer>
                            <ParallaxContainer
                                className="mt-24 flex-col"
                                style={{ minHeight: 450 }}
                            >
                                <img className="self-center top-0 left-32 md:right-32 z-0 "
                                    src={ShapeClients}
                                    width="207px" 
                                    height="207px"
                                    alt="600 Offices Moved into" />
                                <img className="self-center z-1  "
                                    src={ShapeCities}
                                    width="316px" 
                                    height="173px"
                                    alt="7 Different Countries  as Office locations" />

                            </ParallaxContainer>

                        </div>

                    </div>

                    <div className="flex flex-col justify-center w-full md:w-1/2 leading-loose md:px-6 lg:px-12 max-w-xl mr-auto lg:ml-24 md:ml-6 px-8  md:px-0 order-first md:order-second">
                        <p>
                            Now it’s {new Date().getFullYear()}, and things are as interesting as ever.
                        </p>
                        <p>
                            The industry is in flux. Growing demand for a mix of remote and in person “hybrid” working, shifting market economics, innovative solutions dialing up the flexibility. It’s all to play for.
                        </p>
                        <p>
                            Although startups and scaleups are our OGs, these days we’re lucky to work with some other fine folks too. From billion-dollar disruptors to VCs and even like-minded blue-chips too for good measure.
                        </p>
                        <p>
                            We’ve helped people acquire a few square feet of office space over the years… 4.3 million, to be precise. (That’s around 55,000 desks in 690+ offices across 18 cities, but who’s counting?!)
                        </p>
                        <p>
                            Between us we have over 85 years of industry experience. For names, faces and juicy facts, <Link className="underline" to="/team" state={{ fromSite: true }}>meet the people behind Kontor.</Link>
                        </p>
                        <p>
                            We’ve moved offices ten times in nine years. We’ve expanded across the pond from London to New York.
                        </p>
                        <p>
                            Our team of two has grown to 22. And like so many others, we've had our fair share of covid curveballs to deal with. (TLDR: the highs, the lows... we get it.)
                        </p>
                        <p>
                            And we’re excited to join you on your journey.
                        </p>
                    </div>
                </div>
            </div>

            {/* Call to action  */}


            <div className="text-center mt-40">
                <h2 data-aos="fade-up" data-aos-duration="1000" className="font-bold mb-2 text">Need a new office?</h2>
                <h2 data-aos="fade-up" data-aos-duration="1000" className="">We're here to help. Work with us, it'll be fun.</h2>
                <BotButton data-aos="fade-up" data-aos-duration="1000" className="mt-16 mb-32 text-xl" type="secondary">Let's get started!</BotButton>
            </div>


            <FeaturedSection className="mb-40" />
        </Layout>)
}

About.propTypes = {

}

export default About
